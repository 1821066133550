import React, { useEffect, useState, useRef } from "react"
// import { navigate } from "gatsby";
import styles from "./myCovers.module.scss"
import Layout from "../Layout"
import Button from "../Button"
import CardVideo from "../CardVideo"
import { IsMobileDevice } from "../../../utils"
import Slider from "../Slider"
import BudCover from "../TextBudStage"
import SvgIcon from "../SvgIcon"
import { search } from "../../../services"

const Covers = () => {
  const [data, setData] = useState([])
  const [isMobile, setIsMobile] = useState()
  const [limit, setLimit] = useState(16)

  const handleClick = id => {
    return window.location.replace(`/budstage/tema/?id=${id}`)
  }

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    const params = window.location.search.split("=")[1]
    const email = Buffer.from(params, "base64").toString("ascii")
    search("email", limit, 1, email).then(setData)
  }, [])

  const convertToArray = num => {
    let array = []
    for (let i = 0; i < num; i++) {
      array.push(i)
    }
    return array
  }
  const dataSlicer = (dataArray, start, limit) => {
    const slicedData = dataArray.slice(start, limit)
    return slicedData
  }
  const CoversMobileSlider = props => {
    const { data } = props
    const slidersQuantity = Math.ceil(data.length / 4)
    const quantityToArray = convertToArray(slidersQuantity)
    dataSlicer(data)
    let start = 0
    let limit = 4

    return quantityToArray.map(num => {
      let slicedDataSource = []
      if (num) {
        start = start + 4
        limit = limit + 4
      }
      slicedDataSource = dataSlicer(data, start, limit)
      let slidesPerView
      if (slicedDataSource.length === 1) {
        slidesPerView = 0.8
      } else {
        slidesPerView = 1.1
      }
      return (
        <Slider
          slidesPerView={slidesPerView}
          spaceBetween={-50}
          key={`${num}-index`}
          dataSource={slicedDataSource}
          className={styles.sliderCovers}
          onClick={handleClick}
        />
      )
    })
  }

  const handleLimit = () => {
    setLimit(prevState => prevState + 16)
  }

  return (
    <>
      <Layout type="secondary" onlyText>
        <div className={styles.covers}>
          <div className={styles.header}>
            <h1>MIS TEMAS.</h1>
          </div>
          <BudCover orientation="vertical" className={styles.budTextVertical} />
          <SvgIcon
            name="we-brew-icon"
            size="4.290vw"
            className={styles.weBrewIcon}
          />
          <div className={styles.content}>
            {isMobile && <CoversMobileSlider data={data} />}
            {!isMobile && data.length ? (
              data.map(cover => {
                return <CardVideo data={cover} handleClick={handleClick} />
              })
            ) : (
              <div></div>
            )}
          </div>
          <Button label="CARGAR MÁS" type="secondary" onClick={handleLimit} />
        </div>
      </Layout>
    </>
  )
}

export default Covers

import React, { useRef, useState, useEffect } from "react"
import styles from "./slider.module.scss"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"
import { IsMobileDevice } from "../../../utils"

import "../../../styles/slider.css"
import CardVideo from "../CardVideo"

const Slider = props => {
  const { dataSource, className, onClick, slidesPerView, spaceBetween } = props
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const onCardClick = () => {
    window.location = "/budstage/cover"
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.sliderContainer} ${className}`}>
        <Swiper
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          /*        onSlideChange={() => console.log("slide change")}
               onSwiper={swiper => console.log(swiper)} */
        >
          {dataSource &&
            dataSource.map(cover => {
              return (
                <SwiperSlide>
                  {isMobile ? (
                    <CardVideo
                      data={cover}
                      shareType="secondary"
                      className={styles.slideVideoCard}
                      handleClick={onClick}
                    />
                  ) : (
                    <CardVideo
                      data={cover}
                      className={styles.slideVideoCard}
                      handleClick={onClick}
                    />
                  )}
                </SwiperSlide>
              )
            })}
        </Swiper>
      </div>
    </div>
  )
}

export default Slider
